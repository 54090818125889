<template>
  <div>
    <b-overlay :show="isSavingRequestReservation" rounded class="">
      <ValidationObserver
        ref="formRequestReservation"
        tag="form"
        v-slot="{ invalid }"
      >
        <b-form @submit.prevent="handlerSendRequest">
          <b-row> <!-- Indicate your dates, number of adults, children -->
            <b-col cols="12" v-if="isEditing">
              <h6>{{ $t("Indicate your dates, number of adults, children") }}</h6>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <ValidationProvider :name="$t('Type of occupant')" rules="required">
                  <b-form-group slot-scope="{ errors }">
                    <label class="font-weight-bolder">{{$t('Type of occupant')}}</label>
                    <b-form-select
                      v-model="booking.isAGuest"
                      :disabled="!isEditing"
                      :state="errors.length > 0 ? false : null"
                      class="text-capitalize"
                    >
                      <option v-for="guest in guestsOptions" :key="guest.value" :value="guest.value">
                        <span>{{ guest.text }}</span>
                      </option>
                    </b-form-select>
                    <b-form-invalid-feedback>{{errors[0]}}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <ValidationProvider :name="$t('Check in - Check out')" rules="required">
                <b-form-group slot-scope="{ errors }">
                  <label class="font-weight-bolder">{{$t('Check in - Check out')}}</label>
                  <flat-pickr
                    :config="configRangeDate"
                    :class="`form-control flatpickr-color ${errors.length > 0 ? 'is-invalid' : ''}`"
                    v-model="booking.checkInCheckOut"
                    @input="setCheckInChekOut"
                    :disabled="!isEditing"
                    :state="errors.length > 0 ? false : null"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col md="3">
              <div>
                <label class="font-weight-bolder">{{$t('Nights')}}</label>
                <b-form-input :value="booking.nights" disabled/>
              </div>
            </b-col>
          </b-row>

          <div> <!-- Adult info -->
            <b-row>
              <b-col md="3">
                <span class="font-weight-bold">{{ $t("Guests") }}</span>
                <b-row>
                  <b-col md="">
                    <b-form-group>
                      <ValidationProvider :name="$t('Adults')" rules="required">
                        <b-form-group slot-scope="{ errors }">
                          <label class="font-weight-bolder">{{$t('Adults')}}</label>
                          <b-form-select
                            size="sm"
                            v-model="booking.numberAdults"
                            :disabled="!isEditing"
                            :state="errors.length > 0 ? false : null"
                            @change="setAdults"
                          >
                            <option v-for="(adulto, index) in adultOptions" :key="index" :value="adulto.value">
                              {{ adulto.text }}
                            </option>
                          </b-form-select>
                          <b-form-invalid-feedback class="invalid-feedback-custom">{{errors[0]}}</b-form-invalid-feedback>
                        </b-form-group>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
    
                  <b-col md="">
                    <b-form-group>
                      <ValidationProvider :name="$t('Children')" rules="required">
                        <b-form-group slot-scope="{ errors }">
                          <label class="font-weight-bolder">{{$t('Children')}}</label>
                          <b-form-select
                            size="sm"
                            v-model="booking.numberChildren"
                            :disabled="!isEditing"
                            :state="errors.length > 0 ? false : null"
                            @change="setChilds"
                          >
                            <option v-for="(child, index) in childrenOptions" :key="index" :value="child.value">
                              {{ child.text }}
                            </option>
                          </b-form-select>
                          <b-form-invalid-feedback class="invalid-feedback-custom">{{errors[0]}}</b-form-invalid-feedback>
                        </b-form-group>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
    
                  <b-col md="" align-self="center">
                    <b-input-group size="sm" style="width: 7rem;">
                      <b-form-input disabled style="width: 50%;" value="Total"/>
                      <b-form-input disabled style="width: 15%;" :value="booking.numberAdults + booking.numberChildren"/>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-col>

              <b-col md="6" align-self="stretch" v-if="isEditing">
                <span class="font-weight-bold">{{$t('Notes')}}: </span> <br>
                <label>{{ charactersLeft }}</label> <br>
                <b-form-group>
                  <b-input-group>
                    <b-form-textarea
                      :disabled="!isEditing"
                      v-model="booking.comments"
                      :placeholder="$t('Write comments')"
                      :maxlength="max"
                      rows="1"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <h6 class="subtitle font-weight-bolder">{{ $t("Guests list") }}</h6>

            <hr class="mt-0 border-secondary" v-if="isEditing">

            <b-row>
              <b-col md="">
                <div class="" v-if="isEditing">
                  <!-- adults -->
                  <div>
                    <!-- Please indicate the age, name and lastname of each child -->
                    <h6 class="mb-0">{{$t("Please indicate the age, name and lastname of each child")}}</h6>
                    <label class="text-warning">{{$t("Please list each child's first and last name or leave it as NA")}}</label>
                  </div>
                  <div v-if="booking.adultsDetail.length > 0">
                    <span class="font-weight-bold">{{$t('Adults') }}</span>
                    <b-row>
                      <b-col>
                        <label class="">{{$t('name')}}</label>
                      </b-col>
                      <b-col>
                        <label class="">{{$t('lastname')}}</label>
                      </b-col>
                      <b-col>
                        <label class="">{{$t('Email')}}</label>
                      </b-col>
                      <b-col>
                        <!-- <label class="">{{$t('Email')}}</label> -->
                      </b-col>
                    </b-row>
                    <b-row class="row" v-for="(adult, index) in booking.adultsDetail" :key="adult.key">
                      <b-col class="form-group col-md-3">
                        <ValidationProvider :name="$t('Name adult')" rules="required">
                          <b-form-group slot-scope="{ errors }">
                            <b-form-input
                              size="sm"
                              :placeholder="$t('Name adult')"
                              v-model="adult.name"
                              :state="errors.length > 0 ? false : null"
                              :disabled="isGuestEditable({adult, index})"
                            />
                            <b-form-invalid-feedback class="invalid-feedback-custom">{{errors[0]}}</b-form-invalid-feedback>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col class="form-group col-md-3">
                        <ValidationProvider :name="$t('Lastname adult')" rules="required">
                          <b-form-group slot-scope="{ errors }">
                            <b-form-input
                              size="sm"
                              :placeholder="$t('Lastname adult or na')"
                              v-model="adult.lastname"
                              :state="errors.length > 0 ? false : null"
                              :disabled="isGuestEditable({adult, index})"
                            />
                            <b-form-invalid-feedback class="invalid-feedback-custom">{{errors[0]}}</b-form-invalid-feedback>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col class="form-group col-md-3" v-if="adult.ismain || index == 0">
                        <ValidationProvider :name="$t('Email adult')" rules="required">
                          <b-form-group slot-scope="{ errors }">
                            <b-form-input
                              size="sm"
                              :placeholder="$t('Email adult')"
                              v-model="adult.email"
                              :state="errors.length > 0 ? false : null"
                              type="email"
                              :disabled="isGuestEditable({adult, index})"
                            />
                            <b-form-invalid-feedback class="invalid-feedback-custom">{{errors[0]}}</b-form-invalid-feedback>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col class="form-group col-md-3" v-if="false">
                        <ValidationProvider :name="$t('Phone adult')" rules="required">
                          <b-form-group slot-scope="{ errors }">
                            <b-form-input
                              size="sm"
                              :placeholder="$t('Phone adult')"
                              v-model="adult.phone"
                              :disabled="isGuestEditable({adult, index})"
                              :state="errors.length > 0 ? false : null"
                            />
                            <b-form-invalid-feedback class="invalid-feedback-custom">{{errors[0]}}</b-form-invalid-feedback>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col class="form-group col-md-3 d-none">
                        <b-button
                          variant="danger"
                          size="sm"
                          @click="deleteAdult(adult.key)"
                          v-if="index !== 0"
                        > <b-icon icon="trash-fill"></b-icon> {{ $t("Delete") }}
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>

                  <!-- <hr class="mt-0"> -->

                  <!-- childs -->
                  <div v-if="booking.dataChildrens.length > 0">
                    <span class="font-weight-bold">{{$t('Children') }}</span>
                    <b-row>
                      <b-col>
                        <label>{{$t('name') }}</label>
                      </b-col>
                      <b-col>
                        <label>{{$t('lastname') }}</label>
                      </b-col>
                      <b-col>
                        <label>{{$t('Age') }}</label>
                      </b-col>
                      <b-col>
                        <!-- <label>{{$t('Lastname') }}</label> -->
                      </b-col>
                    </b-row>
                    <b-row class="row" v-for="child in booking.dataChildrens" :key="child.key">
                      <b-col class="form-group col-md-3">
                        <ValidationProvider :name="$t('Name child')" rules="required">
                          <b-form-group slot-scope="{ errors }">
                            <b-form-input
                              size="sm"
                              :placeholder="$t('Name child')"
                              v-model="child.name"
                              :state="errors.length > 0 ? false : null"
                            />
                            <b-form-invalid-feedback class="invalid-feedback-custom">{{ errors[0] }}</b-form-invalid-feedback>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col class="form-group col-md-3">
                        <ValidationProvider :name="$t('Lastame child')" rules="required">
                          <b-form-group slot-scope="{ errors }">
                            <b-form-input
                              size="sm"
                              :placeholder="$t('Lastame child')"
                              v-model="child.lastname"
                              :state="errors.length > 0 ? false : null"
                            />
                            <b-form-invalid-feedback class="invalid-feedback-custom">{{errors[0]}}</b-form-invalid-feedback>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col class="form-group col-md-3">
                        <b-form-group>
                          <b-form-select size="sm" v-model="child.edad">
                            <option v-for="(edad, idx) in ageChildrenOptions" :key="idx" :value="edad.value">
                              {{ edad.text }}
                            </option>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col class="form-group col-md-3 d-none">
                        <!-- <label>{{ $t("Delete") }}</label><br /> -->
                        <b-button
                          variant="danger"
                          size="sm"
                          @click="deleteChild(child.key)"
                        > <b-icon icon="trash-fill"></b-icon>
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                  <!-- end -->
                </div>

                <div v-if="!isEditing">
                  <b-row class="mt-2" v-for="(guest, idx) in guestsList" :key="idx">
                    <b-col md="6">
                      <span class="font-weight-bold">{{ idx + 1 }} - {{ guest.name }} {{ guest.lastname }}</span>
                    </b-col>
                    <b-col>
                      <span class="float-center"> ({{ guest.adult ? 'Adult' : `child, ${guest.edad} years` }})</span><br>
                    </b-col>
                  </b-row>
                </div>
              </b-col>

              <b-col md="3" class="mt-1" align-self="stretch" v-if="!isEditing">
                <span class="font-weight-bold">{{$t('Notes')}}: </span> <br>
                <!-- <label>{{ charactersLeft }}</label> <br> -->
                <b-form-group>
                  <b-input-group>
                    <b-form-textarea disabled v-model="booking.comments"/>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col md="3" v-if="!isEditing"/>

            </b-row>
          </div>

          <!-- Enter a note or comment, this is optional -->

          <hr v-if="isEditing" />

          <div class="row mt-1 d-flex justify-content-end" v-if="isEditing"> <!-- Action Buttons -->
            <b-button
              variant="warning"
              :disabled="isSearchingRooms"
              class="mr-2"
              @click="resetForm"
            >
              <span v-if="!isSearchingRooms">
                <font-awesome-icon icon="fa-solid fas fa-undo" /> {{ $t("Cancel") }}
              </span>
            </b-button>

            <b-button
              type="submit"
              variant="primary"
              :disabled="invalid || isSearchingRooms"
            >
              <span v-if="isSearchingRooms">
                <b-spinner
                  variant="success"
                  style="width: 1.5rem; height: 1.5rem"
                /> Sending request...
              </span>
              <span v-if="!isSearchingRooms">
                <feather-icon icon="EditIcon" size="14" />
                <span v-if="isRequest"> {{ $t("Search Rooms") }}</span>
                <span v-else-if="isEditing"> {{ $t("modify") }}  </span>
              </span>
            </b-button>
          </div>
          <UserRestrictedModal/>
          <!-- <br> <pre>{{ booking }}</pre> -->
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </div>
</template>

<script>
import { mapState,mapActions } from "vuex"
import { adultOptions, childrenOptions, ageChildrenOptions } from "@/data/data/"
import { showAlertMessage, stringAleatorio, toJson } from "@/helpers/helpers"
import { currentDate } from "@/helpers/market"
import flatPickr from "vue-flatpickr-component"
import * as moment from "moment"
import { utils } from '@/modules/owners/mixins/utils'
import UserRestrictedModal from '@/modules/layouts/components/UserRestrictedModal'
export default {
  mixins:[utils],
  props: {
    bookingEdit: {
      type: Object,
      default: null,
    },
    isRequest: {
      type: Boolean,
      default: false
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    isSearchingRooms: {
      type: Boolean,
      default: false
    },
    isSavingRequestReservation: {
      type: Boolean,
      default: false
    },
  },
  created() {
    this.setDataAdultDefault()
  },
  data() {
    return {
      adultOptions,
      childrenOptions,
      ageChildrenOptions,
      booking: toJson(this.bookingEdit),
      max: 255,
      rooms: [],
      fechaActual: currentDate(),
      isValidDate: true,
      msgDateValid: null,
      guestsOptions: [
        {value: false, text: this.$t('owner'), class: 'text-capitalize'},
        {value: true, text: this.$t('Invited'), class: 'text-capitalize'}
      ]
    }
  },
  components: {
    flatPickr,
    UserRestrictedModal
  },
  computed: {
    ...mapState("auth", ["user", "myContracts", "benefits", "userAdmin"]),
    charactersLeft() {
    return this.$t("Comments characters left", {
      charactersLeft: this.max - this.booking?.comments.length,
    })
    },
    endContractIsGreaterThanOneYear() {
    let isGreaterThanOneYear = false
    if (this.myContracts) {
      const { dateEnd } = this.myContracts.contractSelected
      const now = moment(currentDate()) //today date
      const end = moment(dateEnd) // end date
      const duration = moment.duration(end.diff(now))
      const year = duration.asYears()
      isGreaterThanOneYear = year > 1
    }
    return isGreaterThanOneYear
    },
    benefitSelected() {
    return this.benefits.find((benefit) => benefit.isSelected)
    },
    configRangeDate() {
    let config = { mode: "range" }
    if (this.myContracts) {
      const { dateStart, dateEnd } = this.myContracts.contractSelected
      const fechaActual = currentDate() //fechaActual
      const currentDatePlusOnYear = moment().add(1, "years").format("YYYY-MM-DD") //a la fechaActual se le suma un año
      //si fecha fin de contrato es mayor que un año la fecha maxima para reservar es hasta un año, sino hasta el fecha fin de contrato
      config.maxDate = this.endContractIsGreaterThanOneYear ? currentDatePlusOnYear : dateEnd
      config.minDate = moment(dateStart).isAfter(fechaActual) ? dateStart : fechaActual
    }
    return config
    },
    owner() {
      const owner = this.user ? { ...this.user } : null
      if (owner) delete owner.gallery
      return owner
    },
    guestsList(){
      return [].concat(this.booking?.adultsDetail, this.booking?.dataChildrens)
    }
  },
  methods: {
    ...mapActions("owners", ["fetchOwnerValidateSigned"]),
    setCheckInChekOut(rangeDate) {
			if (rangeDate.includes("to")) {
        this.isValidDate = true
        this.msgDateValid = null
        const splitDate =  rangeDate.split(" to ")
        this.booking.checkIn = splitDate[0]
        this.booking.checkOut = splitDate[1]
        this.$emit("set-year", this.setYearDateOut(this.booking.checkIn)) //el año se valida con el año del checkin
        const inicio = moment(splitDate[0])
        const fin = moment(splitDate[1])
        this.booking.nights =  splitDate[0] && splitDate[1] ? fin.diff(inicio, "days") : 0
      } else {
        this.booking.checkIn = ""
        this.booking.checkOut = ""
        this.booking.nights = 0
        // this.isValidDate = false
        // this.msgDateValid = this.$t("Missing value for Check in - Check out")
      }
    },
    setYearDateOut(date) {
      return new Date(date).getFullYear()
    },
    setAdults(numberAdults) {
      let adultGuests = []
      this.booking.numberAdults = numberAdults
      if (!this.booking.adultsDetail.find((guest) => guest.ismain == true))
        this.booking.adultsDetail[0].ismain = true

      for (let i = 0; i < numberAdults; i++) {
        const key = stringAleatorio()
        let adultDetail = {
          edad: "",
          email: "",
          adult: true,
          ismain: false,
          key,
          lastname: "NA",
          name: "NA",
          phone: "NA",
        }
        if (i === 0)
          adultDetail = {...this.booking.adultsDetail.find((guest) => guest.ismain == true), key }
        if (Boolean(this.booking.adultsDetail[i]))
          adultDetail = { ...this.booking.adultsDetail[i], key }
          adultGuests.push(adultDetail)
      }
      this.booking.adultsDetail = adultGuests
    },
    setChilds(numberChilds) {
      let dataChildrens = []
      this.booking.numberChildren = numberChilds

      for (let i = 0; i < numberChilds; i++) {
        const key = stringAleatorio()
        let childDetail = {
          edad: 1,
          email: "",
          adult: false,
          ismain: false,
          key,
          lastname: "NA",
          name: "NA",
          phone: "",
        }
        if (Boolean(this.booking.dataChildrens[i]))
          childDetail = { ...this.booking.dataChildrens[i], key }

          dataChildrens.push(childDetail)
      }
      this.booking.dataChildrens = dataChildrens
    },
    setDataAdultDefault() {
      if (!this.booking.adultsDetail.find((guest) => guest.ismain == true))
      this.booking.adultsDetail[0].ismain = true
    },
    async handlerSendRequest() {
      const isValid = await this.$refs.formRequestReservation.validate()

      let msgDate = this.booking.checkInCheckOut === "" ? this.$t("You need to specify a date range") : null

      if (
        isValid &&
        this.booking.checkInCheckOut != "" &&
        this.booking.checkIn != "" &&
        this.booking.checkOut != ""
      ){
        this.isValidDate = true
        this.msgDateValid = null
        // validar si es admin desde mixin en utils
        if( !this.isAdmin  ){
          const idMycontract=this.myContracts.contractSelected.id
          const isSigned = await this.fetchOwnerValidateSigned({idContract:idMycontract})
          //if (!isSigned.validate) return this.$root.$emit('bv::show::modal', 'user-restric-modal')
          if(!isSigned.validate){
            return this.$swal.fire({icon: "error", title: `${this.$t("Restricted Access")}`, html: `${this.$t("Text Restricted Access Owner")}`});
          }
				}

        if( this.isAdmin && this.isEditing ){
          return this.$swal.fire({icon: "error", title: `${this.$t("Restricted Access")}`, html: `${this.$t("Text Restricted Access Admin")}`});
        }
        this.$emit("send-request-booking", this.booking ) // Realizo la solicitud
      } else {
        this.isValidDate = false
        this.msgDateValid = msgDate ? msgDate : this.$t("Missing value for Check in - Check out")
        const title = this.$t("Date: Check in - Check out")
        const msg = this.$t("Missing value for Check in - Check out")
        showAlertMessage(title, "InfoIcon", `😰😰 ${msg}`, "danger", 3000, "bottom-right")
      }
    },
    deleteAdult(keyAdult) {
    this.booking.adultsDetail = this.booking.adultsDetail.filter((adult) => adult.key !== keyAdult)

    this.booking.numberAdults = this.booking.numberAdults - 1
    },
    deleteChild(keyAge) {
    this.booking.dataChildrens = this.booking.dataChildrens.filter(
      (age) => age.key !== keyAge
    )
    this.booking.numberChildren = this.booking.numberChildren - 1
    },
    isGuestEditable(data){
    const {adult, index} = data
    return !this.booking.isAGuest && (adult.ismain || index == 0)
    },
    cancelBooking(){
      this.$emit('request-cancel-booking', this.booking)
    },
    async resetForm() {
      this.booking = toJson(this.bookingEdit)
      this.$emit('set-rooms',[])
      this.$emit('enable-edition')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.flatpickr-color{
  background-color: white !important;
}
.flatpickr-color:disabled{
  background-color: #efefef !important;
}
.manual-disable{
  // background-color: #efefef;
  color: #b8c2cc;
}
.guest-list{
  max-width: 5rem;
}
.invalid-feedback-custom{
  font-size: 75% !important;
}
</style>
